.disconnect-congratulation__text-wrapper {
  max-width: 300px;
  margin: 0 auto; }

@media all and (max-width: 767px) {
  .disconnect-congratulation__title {
    max-width: 200px;
    margin: 0 auto; }
  .disconnect-congratulation__text-wrapper {
    max-width: 170px; }
  .disconnect-congratulation__button-wrapper {
    margin-top: 28px; } }
