.footer--waoo .container, .footer--stackCommerce .container {
  justify-content: center;
  align-items: center; }

.footer--waoo .copyright, .footer--stackCommerce .copyright {
  text-align: center;
  margin-left: 0; }

@media all and (max-width: 767px) {
  .footer--waoo, .footer--stackCommerce {
    min-height: 192px; }
    .footer--waoo .copyright, .footer--stackCommerce .copyright {
      max-width: 200px;
      padding-top: 0;
      transform: translateX(0px);
      margin-top: -25px; }
    .footer--waoo .container, .footer--stackCommerce .container {
      min-height: 192px; } }

@media all and (min-width: 768px) and (max-width: 1240px) {
  .footer--waoo .copyright, .footer--stackCommerce .copyright {
    margin-top: 0; } }
