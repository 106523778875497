.download-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 864px;
  background: #fff;
  margin: 48px auto 0;
  border-radius: 16px; }
  .download-mobile__content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 43px 0 51px;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    border-radius: 16px; }
  .download-mobile__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -1.1px;
    color: #151D51;
    text-align: center;
    margin-bottom: 38px; }
  .download-mobile__info-wrapper {
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row-reverse; }
  .download-mobile__image {
    width: 181px;
    height: 389px;
    display: block;
    image-rendering: auto;
    filter: drop-shadow(0px 15px 25px rgba(0, 0, 0, 0.15));
    border-radius: 9px; }
    .download-mobile__image-wrapper {
      width: 160px;
      margin-left: 80px; }
  .download-mobile__description {
    margin-top: 44px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.41702px;
    color: #151D51;
    width: 100%; }
  .download-mobile__button-section {
    display: none; }
  .download-mobile__text {
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-size: 22px;
    line-height: 30px; }
    .download-mobile__text--bold {
      font-weight: 700; }
  .download-mobile__qr-info {
    display: flex;
    align-items: flex-start;
    text-align: left;
    flex-direction: column-reverse;
    width: 276px;
    transform: translate(-28px, -29px); }
  .download-mobile__qr-wrapper {
    width: 132px;
    height: 132px;
    border: 1px solid #E6E2DD;
    align-items: center;
    display: flex;
    justify-content: center; }
  .download-mobile-page {
    display: block;
    padding: 80px 0 90px;
    max-width: 864px; }
    .download-mobile-page__title {
      font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
      font-size: 60px;
      line-height: 64px;
      text-align: center;
      letter-spacing: -1.1px;
      color: #151D51; }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .download-mobile {
    margin: 40px auto 0;
    width: 100%; }
    .download-mobile__content {
      padding: 34px 0 50px; }
    .download-mobile__text {
      font-size: 20px;
      line-height: 27px;
      letter-spacing: -0.42px; }
    .download-mobile__image {
      width: 144px;
      height: auto; }
      .download-mobile__image-wrapper {
        width: auto;
        margin-left: 50px; }
    .download-mobile__qr {
      transform: scale(0.78); }
      .download-mobile__qr-info {
        width: 250px;
        transform: translate(-6px, -15px); }
      .download-mobile__qr-wrapper {
        width: 104px;
        height: 104px; }
    .download-mobile__description {
      margin-top: 34px; }
    .download-mobile-page {
      padding: 134px 0 55px; }
      .download-mobile-page__title {
        font-size: 48px;
        line-height: 52px;
        letter-spacing: -0.65px; } }

@media all and (max-width: 767px) {
  .download-mobile {
    display: none; }
    .download-mobile__button-section {
      display: block;
      margin-top: 24px; }
    .download-mobile__description {
      margin-top: 25px; }
    .download-mobile__text {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.24889px; }
      .download-mobile__text--highlighted {
        color: #5CD6C0;
        text-decoration: none; }
        .download-mobile__text--highlighted:hover {
          color: #7BDDCB; }
    .download-mobile-page {
      max-width: 100%;
      padding: 88px 0 40px; }
      .download-mobile-page__title {
        font-size: 26px;
        line-height: 30px;
        letter-spacing: -0.44px; } }
