.faq {
  display: block;
  margin-top: 60px; }
  .faq__title {
    display: block;
    text-align: center; }
  .faq__answer {
    display: none;
    transform: translateY(9px);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24889px;
    max-width: calc(100% - 96px);
    transition: height .25s ease;
    overflow: hidden; }
    .faq__answer.active {
      display: block; }
  .faq__answer-text--multi-line {
    padding-top: 16px; }
  .faq__list {
    margin-top: 42px; }
  .faq__list-icon {
    display: block;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid #483FDD;
    background: transparent;
    position: relative; }
    .faq__list-icon::before, .faq__list-icon::after {
      content: '';
      width: 20px;
      height: 2px;
      background-color: #483FDD;
      position: absolute;
      left: calc(50% - 10px);
      top: calc(50%);
      transition: transform 0.35s ease-out; }
    .faq__list-icon::after {
      transform: rotate(90deg); }
  .faq__list-item {
    padding: 24px 0;
    border-bottom: 1px solid #E6E2DD;
    cursor: pointer; }
    .faq__list-item.active .faq__list-icon {
      background: #483FDD; }
      .faq__list-item.active .faq__list-icon::before, .faq__list-item.active .faq__list-icon::after {
        background-color: #fff; }
      .faq__list-item.active .faq__list-icon:before {
        transform: rotate(180deg); }
      .faq__list-item.active .faq__list-icon:after {
        transform: rotate(0deg); }
  .faq__question-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    text-align: left;
    align-items: center; }
  .faq__question {
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.41702px; }
  .faq__footer {
    display: block;
    margin-top: 65px;
    text-align: center; }
  .faq__footer-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24889px; }
  .faq__footer-link {
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.54px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #5CD6C0;
    margin-top: 5px; }

@media all and (max-width: 767px) {
  .faq {
    margin-top: 48px; }
    .faq__title {
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      text-align: center;
      letter-spacing: -0.435556px;
      max-width: 100%; }
    .faq__list {
      margin-top: 21px; }
    .faq__list-icon {
      width: 32px;
      height: 32px; }
      .faq__list-icon::before, .faq__list-icon::after {
        width: 16px;
        left: calc(50% - 8px);
        top: calc(50% - 1px); }
    .faq__question-wrapper {
      display: grid;
      grid-template-columns: 1fr 32px;
      align-items: start; }
    .faq__question {
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.41702px;
      margin-right: 16px; }
    .faq__answer {
      max-width: calc(100% - 48px); }
    .faq__answer-text--inline {
      display: inline; }
    .faq__footer {
      display: none; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .faq {
    margin-top: 69px; }
    .faq__answer-text--inline {
      display: inline; }
    .faq__question-wrapper {
      display: grid;
      grid-template-columns: 1fr 48px;
      align-items: start; }
    .faq__question {
      margin-right: 48px; } }
