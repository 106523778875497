.download-link-expired__page {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 101px 0; }

.download-link-expired__image {
  width: 472px;
  height: 472px;
  border-radius: 50%; }

@media all and (min-width: 1023px) {
  .animate-to-sent .download-link-circle__content-wrapper--link-expired {
    opacity: 0.01;
    animation-name: circleContentHide;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    will-change: opacity; }
  .animate-to-sent .download-link-circle__content-wrapper--link-send,
  .animate-to-sent .download-link-circle__content-wrapper--request-timeout {
    animation-name: circleContentAppear;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.24, 0.25, 0, 0.93);
    will-change: opacity; }
  .animate-to-sent .download-link-expired__image {
    animation-name: moveRight;
    animation-duration: 0.75s;
    animation-timing-function: ease-in-out;
    will-change: opacity;
    opacity: 0;
    z-index: 0;
    transform: translateX(201px); }
  .animate-to-sent .download-link-expired__circle {
    animation-name: moveLeft;
    animation-duration: 0.75s;
    animation-timing-function: ease-in-out;
    opacity: 1;
    z-index: 1;
    transform: translateX(-201px); } }

@media all and (max-width: 767px) {
  .download-link-expired {
    margin: 96px 0 40px;
    flex-wrap: wrap;
    flex-direction: column; }
    .download-link-expired__page {
      position: relative;
      display: block;
      height: 300px; }
      .download-link-expired__page--tokenExpired {
        height: 600px; }
    .download-link-expired__circle {
      margin-left: 0;
      position: absolute;
      left: calc(50% - 150px); }
    .download-link-expired__image {
      position: absolute;
      width: 300px;
      height: 300px;
      top: 260px;
      left: calc(50% - 150px);
      margin: 0 auto; } }

@media all and (max-width: 320px) {
  .download-link-expired {
    transform: translateX(-2px); } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .download-link-expired {
    flex-wrap: wrap;
    flex-direction: column; }
    .download-link-expired__page {
      position: relative;
      display: block;
      height: 472px;
      margin-top: 130px; }
      .download-link-expired__page--tokenExpired {
        height: 850px; }
    .download-link-expired__circle {
      margin-left: 0;
      position: absolute;
      left: calc(50% - 236px); }
    .download-link-expired__image {
      position: absolute;
      width: 472px;
      height: 472px;
      top: 410px;
      left: calc(50% - 236px);
      margin: 0 auto; } }

@media all and (max-width: 900px) and (orientation: landscape) {
  .download-link-expired {
    margin: 96px 0 40px;
    flex-wrap: wrap;
    flex-direction: column; }
    .download-link-expired__page {
      position: relative;
      display: block;
      height: 300px; }
      .download-link-expired__page--tokenExpired {
        height: 600px; }
    .download-link-expired__circle {
      margin-left: 0;
      position: absolute;
      left: calc(50% - 150px); }
    .download-link-expired__image {
      position: absolute;
      width: 300px;
      height: 300px;
      top: 260px;
      left: calc(50% - 150px);
      margin: 0 auto; } }

@media all and (max-width: 1023px) {
  .animate-to-sent .download-link-circle__content-wrapper--link-expired {
    opacity: 0.01;
    animation-name: circleContentHide;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    will-change: opacity; }
  .animate-to-sent .download-link-circle__content-wrapper--link-send,
  .animate-to-sent .download-link-circle__content-wrapper--request-timeout {
    animation-name: circleContentAppear;
    animation-duration: 2s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.24, 0.25, 0, 0.93);
    will-change: opacity; }
  .animate-to-sent .download-link-expired__image {
    transition: top 1s ease-in-out, opacity 1.5s ease-in-out;
    top: 0;
    opacity: 0; }
  .animate-to-sent.download-link-expired__page {
    height: 300px;
    transition: height 1s ease-in-out; }
  .animate-to-sent .download-link-expired__circle {
    opacity: 1;
    z-index: 1;
    transform: translateX(0); } }

@keyframes circleContentAppear {
  0% {
    opacity: 0.01; }
  75% {
    opacity: 1; }
  100% {
    opacity: 1;
    z-index: 2; } }

@keyframes circleContentHide {
  0% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 0;
    z-index: 0; } }

@keyframes moveRight {
  0% {
    transform: translateX(0);
    opacity: 1; }
  100% {
    opacity: 0;
    transform: translateX(201px); } }

@keyframes moveLeft {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-201px); } }
