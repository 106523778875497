.download-link-circle {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  color: #fff;
  position: relative; }
  .download-link-circle--tokenExpired {
    margin-left: -70px; }
  .download-link-circle__content-wrapper {
    display: block;
    position: absolute;
    width: 472px;
    height: 472px; }
    .download-link-circle__content-wrapper--link-expired {
      opacity: 1;
      z-index: 2; }
    .download-link-circle__content-wrapper--link-send {
      opacity: 0;
      z-index: 0; }
    .download-link-circle__content-wrapper--request-timeout {
      opacity: 0;
      z-index: 0; }
  .download-link-circle__content {
    display: block;
    max-width: 90%;
    margin: 0 auto; }
  .download-link-circle__title {
    max-width: 80%;
    margin: 60px auto 5px;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px; }
    .download-link-circle__title--expired {
      margin: 45px auto 5px; }
  .download-link-circle .circle {
    width: 472px;
    height: 472px;
    background-color: rgba(108, 29, 160, 0.95); }
  .download-link-circle .circle__text {
    max-width: 100%; }
  .download-link-circle__text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    max-width: 80%;
    margin: 0 auto; }
    .download-link-circle__text--bold {
      font-weight: bold; }
    .download-link-circle__text--support {
      cursor: pointer; }
  .download-link-circle__button-wrapper {
    margin-top: 18px; }
  .download-link-circle__footer {
    display: block;
    position: absolute;
    bottom: 99px;
    width: 90%;
    max-height: 36px; }
  .download-link-circle--invalidToken .download-link-circle__footer {
    bottom: 110px; }
  .download-link-circle--invalidToken .download-link-circle__title {
    margin: 90px auto 5px; }

.download-link-circle-footer__content {
  display: block;
  max-width: 300px;
  margin: 0 auto; }

@media all and (max-width: 767px) {
  .download-link-circle__content-wrapper {
    width: 300px; }
  .download-link-circle__title {
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    letter-spacing: -0.524258px;
    margin: -10px auto 10px; }
  .download-link-circle--invalidToken .download-link-circle__title {
    margin: 20px auto 10px; }
  .download-link-circle--invalidToken .download-link-circle__footer {
    bottom: 0;
    top: 175px; }
  .download-link-circle__text {
    font-size: 13px;
    line-height: 18px; }
  .download-link-circle__button-wrapper {
    margin-top: 20px;
    margin-bottom: 13px; }
  .download-link-circle__footer {
    display: block;
    position: absolute;
    top: 195px;
    bottom: 0; }
  .download-link-circle .circle {
    width: 300px;
    height: 300px;
    background-color: #6C1DA0; } }

@media all and (max-width: 900px) and (orientation: landscape) {
  .download-link-circle__content-wrapper {
    width: 300px; }
  .download-link-circle__title {
    font-size: 28px;
    line-height: 31px;
    text-align: center;
    letter-spacing: -0.524258px;
    margin: -10px auto 10px; }
  .download-link-circle--invalidToken .download-link-circle__title {
    margin: 20px auto 10px; }
  .download-link-circle--invalidToken .download-link-circle__footer {
    bottom: 0;
    top: 175px; }
  .download-link-circle__button {
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    padding: 0 32px;
    letter-spacing: -0.25px; }
  .download-link-circle__text {
    font-size: 13px;
    line-height: 18px; }
  .download-link-circle__button-wrapper {
    margin-top: 20px;
    margin-bottom: 13px; }
  .download-link-circle__footer {
    display: block;
    position: absolute;
    top: 195px;
    bottom: 0; }
  .download-link-circle .circle {
    width: 300px;
    height: 300px;
    background-color: #6C1DA0; } }
