.membership {
  box-sizing: border-box;
  display: block;
  padding-top: 94px;
  min-height: 0;
  transition: all 0.5s linear; }
  .membership--animate {
    transition: all 0s linear; }
  .membership__subtitle {
    padding-top: 25px; }
  .membership__section-wrapper {
    display: block;
    margin: 0 auto; }
  .membership__section {
    display: flex;
    justify-content: center; }
  .membership__title {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    transform: translateY(50px);
    opacity: 0.01; }

@media all and (max-width: 767px) {
  .membership {
    padding-top: 92px;
    overflow: hidden; }
    .membership__title {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: -0.44px; }
    .membership__subtitle {
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.24889px;
      padding-top: 10px;
      width: 100%; }
    .membership__section-wrapper {
      margin-top: 44px;
      margin-bottom: 48px; }
    .membership__section {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: repeat(3, auto); } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .membership {
    padding-top: calc(140px + 72px); }
    .membership__section-wrapper {
      overflow: hidden;
      margin-top: 67px;
      margin-bottom: 72px; }
    .membership__section {
      display: grid;
      grid-template-columns: 272px 272px;
      grid-gap: 30px; }
    .membership__title {
      font-size: 48px;
      line-height: 52px;
      letter-spacing: -0.65px; } }
