.header {
  position: absolute;
  top: 0;
  z-index: 4;
  width: 100%;
  height: 72px;
  background: #fff;
  font-size: 20px;
  transition: all 0.3s linear;
  color: #151D51;
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  -webkit-backdrop-filter: blur(100px);
          backdrop-filter: blur(100px); }
  .header__container {
    margin: 0 auto;
    position: relative;
    max-width: 1440px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 64px; }
  .header__logo {
    display: block;
    outline: none; }
  .header__menu {
    display: flex; }
  .header__menu-icon--logout {
    display: none; }
  .header__menu-item {
    position: relative;
    margin-left: 36px;
    width: 100%;
    text-align: center;
    cursor: pointer; }
    .header__menu-item:first-child {
      margin-left: 0; }
    .header__menu-item--logout::before {
      position: absolute;
      display: block;
      width: 1px;
      height: 32px;
      background-color: rgba(202, 203, 202, 0.35);
      content: '';
      top: -6px;
      left: -18px; }
    .header__menu-item:hover .header__menu-link, .header__menu-item:focus .header__menu-link {
      outline: none;
      color: #6C1DA0; }
    .header__menu-item:hover .header__menu-icon--logout path, .header__menu-item:focus .header__menu-icon--logout path {
      stroke: #6C1DA0; }
  .header__btn-wrapper {
    display: none; }
  .header__menu-link {
    color: #151D51;
    font-weight: 400;
    text-decoration: none;
    white-space: nowrap; }
    .header__menu-link--active {
      color: #6C1DA0; }

@media all and (max-width: 1023px) {
  .header {
    height: 56px; }
    .header--open {
      z-index: 2147483647;
      position: fixed;
      height: 100%;
      top: 0;
      transition: height 0.3s linear; } }
    @media all and (max-width: 1023px) and (max-width: 768px) and (orientation: landscape) {
      .header--open .header__container {
        display: block;
        padding: 0;
        overflow: scroll; } }

@media all and (max-width: 1023px) {
      .header--open .header__nav {
        background-color: rgba(243, 244, 246, 0.9);
        mix-blend-mode: normal;
        -webkit-backdrop-filter: blur(100px);
                backdrop-filter: blur(100px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 32px 0; }
      .header--open .header__nav-close {
        background: rgba(0, 0, 0, 0.5);
        mix-blend-mode: normal;
        transform: matrix(-1, 0, 0, 1, 0, 0); }
      .header--open .header__menu {
        display: flex;
        width: 184px; }
      .header--open .header__nav-content {
        display: grid;
        height: calc(100% - 56px);
        margin-top: 0;
        width: 100%;
        grid-template-columns: 1fr 72px; }
    .header__container {
      padding: 0;
      display: block;
      min-height: 100%;
      align-items: center;
      justify-content: center; }
    .header__btn-wrapper {
      display: block;
      position: absolute;
      top: 19px;
      right: 24px;
      width: 24px;
      height: 24px;
      transition: opacity 0.5s ease; }
      .header__btn-wrapper--open .header__btn-line:first-child {
        transform: translateY(4px) translateX(0) rotate(45deg); }
      .header__btn-wrapper--open .header__btn-line:nth-child(2) {
        transform: translateY(-4px) translateX(0) rotate(-45deg); }
      .header__btn-wrapper--open .header__btn-line:nth-child(3) {
        opacity: 0; }
    .header__menu {
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .header .header__menu-item:nth-child(0n) {
      animation-delay: 0s; }
    .header .header__menu-item:nth-child(1n) {
      animation-delay: 0.1s; }
    .header .header__menu-item:nth-child(2n) {
      animation-delay: 0.2s; }
    .header .header__menu-item:nth-child(3n) {
      animation-delay: 0.3s; }
    .header .header__menu-item:nth-child(4n) {
      animation-delay: 0.4s; }
    .header .header__menu-item:nth-child(5n) {
      animation-delay: 0.5s; }
    .header .header__menu-item:nth-child(6n) {
      animation-delay: 0.6s; }
    .header .header__menu-item:nth-child(7n) {
      animation-delay: 0.7s; }
    .header .header__menu-item:nth-child(8n) {
      animation-delay: 0.8s; }
    .header .header__menu-item:nth-child(9n) {
      animation-delay: 0.9s; }
    .header .header__menu-item:nth-child(10n) {
      animation-delay: 1s; }
    .header__menu-item {
      animation-name: textItemAppear;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.24, 0.25, 0, 0.93);
      will-change: opacity, transform;
      opacity: 0;
      margin-left: 0;
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.44px;
      text-align: left; }
      .header__menu-item--logout {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-top: 30px; }
        .header__menu-item--logout::before {
          position: absolute;
          content: '';
          display: block;
          width: 100%;
          height: 0;
          border-top: 1px solid #fff;
          top: -21px;
          left: 0;
          right: 0; }
      .header__menu-item:last-child {
        margin-bottom: 0; }
    .header__menu-icon--logout {
      display: block;
      width: 40px;
      margin-right: 14px; }
    .header__logo {
      position: relative;
      display: block;
      transform: scale(0.7);
      top: 0;
      height: 56px;
      background-color: white;
      width: 100%;
      line-height: 72px;
      left: 0;
      text-align: center; }
    .header__btn-line {
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.5s ease;
      width: 100%;
      height: 2px;
      background-color: #151D51; }
      .header__btn-line:first-child {
        top: 0; }
      .header__btn-line:nth-child(2) {
        display: block;
        top: 8px; }
      .header__btn-line:last-child {
        top: 16px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .header {
    height: 72px; }
    .header__menu {
      margin-top: -72px; }
    .header--open {
      z-index: 2147483647;
      height: 100%; }
      .header--open .header__nav {
        background-color: rgba(243, 244, 246, 0.9);
        mix-blend-mode: normal;
        -webkit-backdrop-filter: blur(100px);
                backdrop-filter: blur(100px);
        display: flex;
        justify-content: center;
        align-items: center; }
      .header--open .header__nav-close {
        background: rgba(0, 0, 0, 0.5);
        mix-blend-mode: normal;
        transform: matrix(-1, 0, 0, 1, 0, 0); }
      .header--open .header__menu {
        display: flex;
        width: 248px; }
      .header--open .header__menu-item {
        margin-bottom: 20px;
        font-size: 28px;
        font-weight: 400;
        line-height: 52px;
        letter-spacing: -0.44px;
        text-align: left; }
        .header--open .header__menu-item--logout {
          display: flex; }
      .header--open .header__menu-link {
        width: 248px;
        display: flex;
        justify-content: flex-start;
        box-sizing: border-box; }
    .header__logo {
      position: relative;
      display: block;
      transform: scale(1);
      top: 0;
      height: 72px;
      background-color: white;
      width: 100%;
      line-height: 89px;
      left: 0;
      text-align: center; }
    .header__btn-wrapper {
      top: 27px;
      right: 48px; } }
