.button {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background: transparent;
  border: 0;
  outline: 0;
  text-align: center;
  cursor: pointer;
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  margin: 0;
  box-shadow: none;
  padding: 0;
  opacity: 1;
  border-radius: 0;
  text-transform: none;
  min-width: auto;
  max-width: none;
  transform: none;
  box-sizing: border-box;
  height: 48px;
  font-size: 16px;
  letter-spacing: -0.25px;
  padding: 0 32px;
  display: block;
  border-radius: 30px;
  line-height: 30px;
  transition: all 0.3s linear;
  cursor: pointer;
  border: none;
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  text-decoration: none;
  font-weight: bold;
  min-width: 144px; }
  .button--full-width {
    width: 100%; }
  .button--half-width {
    width: calc(50% - 10px); }
  .button--underline {
    text-decoration: underline; }
  .button__arrow {
    display: none; }
  .button--arrow {
    height: 48px;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.25px;
    padding: 0 64px 0 32px;
    transition: all .5s linear;
    background: transparent;
    text-shadow: none; }
    .button--arrow .button__arrow {
      position: absolute;
      display: inline-block;
      width: 48px;
      background-color: transparent; }
      .button--arrow .button__arrow::before, .button--arrow .button__arrow::after {
        position: absolute;
        display: block;
        content: '';
        width: 9px;
        height: 2px;
        background-color: #483FDD;
        transition: transform .3s; }
      .button--arrow .button__arrow:before {
        top: 20px;
        left: 19px;
        transform: rotate(-45deg); }
      .button--arrow .button__arrow:after {
        top: 20px;
        left: 13px;
        transform: rotate(45deg); }
    .button--arrow, .button--arrow:visited {
      border: 2px solid #483FDD;
      color: #483FDD;
      line-height: 44px; }
    .button--arrow:hover, .button--arrow--hover {
      border: 2px solid #5579F0;
      color: #5579F0;
      transition: all 0.3s linear; }
      .button--arrow:hover .button__arrow::before, .button--arrow:hover .button__arrow::after, .button--arrow--hover .button__arrow::before, .button--arrow--hover .button__arrow::after {
        background-color: #5579F0; }
    .button--arrow-up .button__arrow {
      background-color: red; }
      .button--arrow-up .button__arrow:before {
        top: 20px;
        left: 19px;
        transform: rotate(45deg); }
      .button--arrow-up .button__arrow:after {
        top: 20px;
        left: 13px;
        transform: rotate(-45deg); }
  .button--primary.button--green, .button--primary:visited.button--green {
    background: #5CD6C0;
    color: #151D51; }
  .button--primary.button--purple, .button--primary:visited.button--purple {
    background: #6C1DA0;
    color: #fff; }
  .button--primary.button--dark, .button--primary:visited.button--dark {
    background: #483FDD;
    color: #fff; }
  .button--primary.button--warning, .button--primary:visited.button--warning {
    background: #DF723B;
    color: #fff; }
  .button--primary.button--error, .button--primary:visited.button--error {
    background: #DF723B;
    color: #fff; }
  .button--primary:hover.button--green, .button--primary--hover.button--green {
    background: #7BDDCB;
    transition: all 0.3s linear; }
  .button--primary:hover.button--purple, .button--primary--hover.button--purple {
    background: #7C42D5;
    transition: all 0.3s linear; }
  .button--primary:hover.button--dark, .button--primary--hover.button--dark {
    background: #5579F0;
    transition: all 0.3s linear; }
  .button--primary:hover.button--warning, .button--primary--hover.button--warning {
    background: #DF723B; }
  .button--primary:hover.button--error, .button--primary--hover.button--error {
    background: #DF723B; }
  .button--primary:active.button--green, .button--primary--active.button--green, .button--primary:focus.button--green {
    background: #009796; }
  .button--primary:active.button--purple, .button--primary--active.button--purple, .button--primary:focus.button--purple {
    background: #6C1DA0; }
  .button--primary:active.button--dark, .button--primary--active.button--dark, .button--primary:focus.button--dark {
    background: #151D51; }
  .button--primary:active.fl-button--error, .button--primary--active.fl-button--error, .button--primary:focus.fl-button--error {
    background: #DF723B; }
  .button--primary:disabled, .button--primary.button--disabled {
    cursor: default; }
    .button--primary:disabled.button--green, .button--primary.button--disabled.button--green {
      background: #5CD6C0;
      color: #fff;
      opacity: 0.5; }
    .button--primary:disabled.button--purple, .button--primary.button--disabled.button--purple {
      background: #6C1DA0;
      color: #fff;
      opacity: 0.5; }
    .button--primary:disabled.button--dark, .button--primary.button--disabled.button--dark {
      background: #483FDD;
      color: #fff;
      opacity: 0.5; }
    .button--primary:disabled.button--warning, .button--primary.button--disabled.button--warning {
      background: #DF723B;
      color: #fff;
      opacity: 0.5; }
    .button--primary:disabled.button--error, .button--primary.button--disabled.button--error {
      background: #DF723B;
      color: #fff;
      opacity: 0.5; }
  .button--secondary {
    background: transparent;
    text-shadow: none;
    height: 48px;
    padding: 0 32px;
    font-size: 16px;
    letter-spacing: -0.25px; }
    .button--secondary, .button--secondary:visited {
      border: 2px solid #483FDD;
      color: #483FDD;
      line-height: 44px; }
    .button--secondary:hover, .button--secondary--hover {
      border: 2px solid #5579F0;
      color: #fff;
      background: #5579F0;
      transition: all 0.3s linear; }
    .button--secondary:active, .button--secondary--active, .button--secondary:focus {
      border: 2px solid #483FDD;
      color: #fff;
      background: #483FDD;
      transition: all 0.3s linear; }
    .button--secondary:disabled, .button--secondary--disabled {
      border: 2px solid #5CD6C0;
      color: #009796;
      cursor: default;
      opacity: 0.5; }
  .button--third {
    background: transparent; }
    .button--third.button--green, .button--third:visited.button--green {
      color: #5CD6C0; }
    .button--third.button--purple, .button--third:visited.button--purple {
      background: #6C1DA0; }
    .button--third.button--warning, .button--third:visited.button--warning {
      color: #DF723B; }
    .button--third.button--error, .button--third:visited.button--error {
      color: #DF723B; }
    .button--third.button--info, .button--third:visited.button--info {
      color: #5CD6C0; }
    .button--third:hover.button--green, .button--third--hover.button--green {
      color: #7BDDCB; }
    .button--third:hover.button--purple, .button--third--hover.button--purple {
      background: #7C42D5; }
    .button--third:hover.button--warning, .button--third--hover.button--warning {
      color: #FF9946; }
    .button--third:hover.button--error, .button--third--hover.button--error {
      color: #FF6666; }
    .button--third:hover.button--info, .button--third--hover.button--info {
      color: #7BDDCB; }
    .button--third:active.button--green, .button--third--active.button--green, .button--third:focus.button--green {
      color: #009796; }
    .button--third:active.button--purple, .button--third--active.button--purple, .button--third:focus.button--purple {
      background: #6C1DA0; }
    .button--third:active.button--warning, .button--third--active.button--warning, .button--third:focus.button--warning {
      color: #EB791D; }
    .button--third:active.button--error, .button--third--active.button--error, .button--third:focus.button--error {
      color: #EB3F41; }
    .button--third:active.button--info, .button--third--active.button--info, .button--third:focus.button--info {
      color: #009796; }
    .button--third:disabled, .button--third--disabled {
      cursor: default;
      opacity: 0.5; }
      .button--third:disabled.button--green, .button--third--disabled.button--green {
        color: #5CD6C0; }
      .button--third:disabled.button--purple, .button--third--disabled.button--purple {
        background: #6C1DA0; }
      .button--third:disabled.button--warning, .button--third--disabled.button--warning {
        color: #DF723B; }
      .button--third:disabled.button--error, .button--third--disabled.button--error {
        color: #DF723B; }
      .button--third:disabled.button--info, .button--third--disabled.button--info {
        color: #5CD6C0; }

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 24px; }
  .button-wrapper--forgot {
    margin-top: 48px; }
  .button-wrapper--error {
    margin-top: 42px; }

.button--logout {
  position: absolute;
  opacity: 0.01;
  top: 96px;
  width: 164px;
  right: 64px; }

@media all and (max-width: 767px) {
  .button-wrapper {
    margin-top: 16px; }
    .button-wrapper--forgot {
      margin-top: 32px; }
    .button-wrapper--error-visible {
      display: inline-block;
      margin: 44px auto; }
  .button--primary {
    height: 48px;
    font-size: 16px;
    line-height: 48px;
    letter-spacing: -0.25px;
    padding: 0 32px;
    font-weight: bold; } }
  @media all and (max-width: 767px) and (hover: hover) {
    .button--primary:hover.button--green, .button--primary:focus.button--green {
      background: #7BDDCB; }
    .button--primary:hover.button--purple, .button--primary:focus.button--purple {
      background: #7C42D5; }
    .button--primary:hover.button--warning, .button--primary:focus.button--warning {
      background: #FF9946; }
    .button--primary:hover.button--error, .button--primary:focus.button--error {
      background: #FF6666; }
    .button--primary:hover.button--info, .button--primary:focus.button--info {
      background: #7BDDCB; } }

@media all and (max-width: 767px) and (hover: none) {
  .button--secondary:hover, .button--secondary:focus {
    border: 2px solid #483FDD;
    color: #483FDD;
    background: #fff; } }

@media all and (max-width: 767px) and (hover: hover) {
  .button--secondary:hover, .button--secondary:focus {
    border: 2px solid #5579F0;
    color: #fff;
    background: #5579F0;
    transition: all 0.3s linear; } }

@media all and (max-width: 767px) and (hover: hover) {
  .button--third:hover.button--green, .button--third:focus.button--green {
    color: #7BDDCB; }
  .button--third:hover.button--purple, .button--third:focus.button--purple {
    color: #7C42D5; }
  .button--third:hover.button--warning, .button--third:focus.button--warning {
    color: #FF9946; }
  .button--third:hover.button--error, .button--third:focus.button--error {
    color: #FF6666; }
  .button--third:hover.button--info, .button--third:focus.button--info {
    color: #7BDDCB; } }

@media all and (max-width: 767px) {
  .button--logout {
    display: none;
    z-index: 3;
    font-size: 14px;
    line-height: 21px;
    min-width: 78px;
    width: 78px;
    right: 24px;
    padding: 0;
    height: 32px;
    top: 72px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .button--logout {
    display: none;
    right: 48px; } }

@media all and (min-width: 768px) and (max-width: 1023px) and (hover: none) {
  .button--secondary:hover, .button--secondary:focus {
    border: 2px solid #483FDD;
    color: #483FDD;
    background: #fff; } }

@media all and (min-width: 768px) and (max-width: 1023px) and (hover: hover) {
  .button--secondary:hover, .button--secondary:focus {
    border: 2px solid #5579F0;
    color: #fff;
    background: #5579F0;
    transition: all 0.3s linear; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .button-wrapper--error-visible {
    display: inline-block;
    margin: 44px auto; } }
