.download-page {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  color: #151D51; }
  .download-page__content {
    display: flex;
    flex-direction: row;
    padding: 60px 64px 30px; }
  .download-page__section {
    display: block; }
  .download-page__text-block {
    width: 478px;
    align-self: center;
    text-align: left; }
  .download-page__title {
    font-weight: bold;
    font-size: 48px;
    line-height: 57px;
    letter-spacing: -1.1px; }
  .download-page__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.248889px; }
    .download-page__text--subtitle {
      margin-top: 17px;
      max-width: 352px; }

@media all and (max-width: 1330px) and (min-width: 1023px) {
  .download-page__content {
    display: block; }
  .download-page__text-block {
    width: 100%;
    text-align: center;
    margin-bottom: 48px; }
  .download-page__text--subtitle {
    max-width: 100%;
    display: block; } }

@media all and (max-width: 767px) {
  .download-page__content {
    display: flex;
    flex-direction: row;
    padding: 48px 0; }
  .download-page__notification {
    margin-top: 56px; }
  .download-page__title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1.1px; }
  .download-page__text--subtitle {
    max-width: 80%;
    display: block;
    margin: 10px auto; }
  .download-page__content {
    display: block; }
  .download-page__text-block {
    width: 100%;
    text-align: center;
    margin-bottom: 48px; } }

@media all and (max-width: 1023px) and (min-width: 768px) {
  .download-page__content {
    display: block; }
  .download-page__text-block {
    width: 100%;
    text-align: center;
    margin-bottom: 48px; }
  .download-page__notification {
    margin-top: 72px; }
  .download-page__text--subtitle {
    max-width: 80%;
    display: block;
    margin: 20px auto 0; } }
