.header--logo-waoo .header__container, .header--logo-stackCommerce .header__container, .header--logo-mkClarioMobile .header__container {
  justify-content: center; }

@media all and (max-width: 767px) {
  .header--logo-waoo .header__logo-block, .header--logo-stackCommerce .header__logo-block, .header--logo-mkClarioMobile .header__logo-block {
    position: relative;
    display: block;
    transform: scale(0.7);
    top: 0;
    height: 56px;
    background-color: white;
    width: 100%;
    line-height: 72px;
    left: 0;
    text-align: center; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .header--logo-waoo .header__logo-block, .header--logo-stackCommerce .header__logo-block, .header--logo-mkClarioMobile .header__logo-block {
    position: relative;
    display: block;
    transform: scale(1);
    top: 0;
    height: 72px;
    background-color: white;
    width: 100%;
    line-height: 89px;
    left: 0;
    text-align: center; } }
