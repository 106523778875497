.download-instruction {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  color: #151D51;
  margin-top: calc(159px - 72px); }
  .download-instruction__title {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: #151D51; }
    .download-instruction__title--download-instruction {
      max-width: 90%;
      margin: 0 auto;
      text-align: center; }
  .download-instruction__text {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24889px;
    color: #151D51; }
  .download-instruction__manual-download {
    margin-top: 46px; }
  .download-instruction__link {
    padding-left: 6px;
    font-weight: bold; }
  .download-instruction__greeting {
    position: relative;
    display: block;
    box-sizing: border-box; }
  .download-instruction__separator {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    height: 1px;
    left: 50%;
    margin-top: 87px;
    background-color: #E6E2DD; }
  .download-instruction__source-button {
    width: 135px;
    height: 44px;
    margin-top: 12px; }
    .download-instruction__source-button:first-child {
      margin-right: 10px; }

.download-instruction {
  margin: 88px 0 0;
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif; }
  .download-instruction__title {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.43556px; }
  .download-instruction__list {
    display: flex;
    justify-content: flex-start;
    margin: 54px 109px; }
  .download-instruction__item {
    display: block;
    margin-right: 37px;
    width: 33.3%; }
    .download-instruction__item:last-child {
      margin-right: 0;
      margin-left: 12px; }
  .download-instruction__number {
    vertical-align: top;
    display: inline-block;
    padding: 8px;
    transform: translateY(-18px); }
  .download-instruction__number-circle {
    display: inline-block;
    width: 48px;
    height: 48px;
    background-color: #483FDD;
    border-radius: 50%;
    font-weight: bold;
    font-size: 28px;
    line-height: 48px;
    color: #fff; }

.instruction-step {
  display: inline-block;
  text-align: left;
  margin-left: 10px;
  width: 75%; }
  .instruction-step--stores {
    max-width: 286px; }
  .instruction-step__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.41702px; }
  .instruction-step__description {
    margin-top: 9px; }
  .instruction-step__store-buttons {
    display: flex; }

.download-benefits {
  display: block;
  background-color: #5CD6C0;
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%; }
  .download-benefits__content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 121px 130px 147px;
    display: flex; }
  .download-benefits__image {
    display: block;
    width: 510px;
    height: 520px;
    margin-right: 80px; }
  .download-benefits__description {
    display: block;
    text-align: left;
    max-width: 576px; }
  .download-benefits__title {
    margin-top: 68px; }
  .download-benefits__list {
    margin-top: 37px;
    display: block; }
  .download-benefits__item {
    position: relative;
    display: block;
    padding-left: 27px;
    margin-bottom: 12px;
    max-width: 88%; }
    .download-benefits__item::before {
      content: '';
      position: absolute;
      display: block;
      width: 8px;
      height: 8px;
      background-color: #483FDD;
      border-radius: 50%;
      top: 7px;
      left: 3px; }
  .download-benefits__conclusion {
    margin-top: 41px;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.41702px;
    color: #151D51; }

@media all and (max-width: 767px) {
  .download-instruction {
    margin-top: calc(56px + 40px); }
    .download-instruction__title {
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.85px; }
      .download-instruction__title--download-instruction {
        text-align: center; }
      .download-instruction__title--separate {
        display: block; }
    .download-instruction__manual-download {
      display: none !important; }
    .download-instruction__text {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0; }
    .download-instruction__link {
      padding-left: 0;
      display: block;
      font-size: 16px;
      padding-top: 3px; }
    .download-instruction__separator {
      margin-top: 47px;
      width: 100%;
      left: inherit;
      margin-left: 0; }
    .download-instruction__source-button {
      width: 101px;
      height: 33px; }
      .download-instruction__source-button:first-child {
        margin-right: 7px; }
  .download-instruction__title {
    font-size: 20px;
    line-height: 24px; }
  .download-instruction__list {
    flex-direction: column;
    margin: 31px 0; }
  .download-instruction__item {
    margin-right: 0;
    margin-bottom: 41px;
    position: relative;
    text-align: left;
    width: 100%; }
    .download-instruction__item:last-child {
      margin-bottom: 0;
      margin-left: 0; }
  .download-instruction__number {
    padding: 6px;
    transform: translateY(-10px); }
  .download-instruction__number-circle {
    width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 36px;
    text-align: center; }
  .instruction-step {
    max-width: 70%; }
    .instruction-step__store-buttons {
      display: flex; }
    .instruction-step__title {
      font-size: 16px;
      line-height: 24px; }
  .download-benefits__content {
    padding: 48px 24px;
    display: block; }
  .download-benefits__image {
    width: 202px;
    height: 205px;
    margin: 0 auto; }
  .download-benefits__title {
    margin-top: 48px;
    text-align: center; }
  .download-benefits__conclusion {
    font-size: 16px;
    line-height: 24px;
    margin-top: 38px;
    max-width: 95%; } }

@media all and (min-width: 768px) and (max-width: 1280px) {
  .download-instruction__title--separate {
    display: block; }
  .download-instruction__manual-download {
    display: none !important; }
  .download-instruction__list {
    flex-direction: column; }
  .download-instruction__item {
    margin-right: 0;
    margin-bottom: 48px;
    position: relative;
    text-align: left;
    width: 100%; }
    .download-instruction__item:last-child {
      margin-bottom: 0;
      margin-left: 0; }
  .download-instruction__number-circle {
    text-align: center; }
  .download-benefits__content {
    display: block; }
  .download-benefits__image {
    margin: 0 auto; }
  .download-benefits__list {
    text-align: left; }
  .download-benefits__description {
    text-align: center;
    margin: 0 auto;
    max-width: 576px; }
  .download-benefits__conclusion {
    text-align: left; } }
