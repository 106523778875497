.circle-button {
  position: absolute;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(21, 29, 81, 0.25);
  cursor: pointer; }
  .circle-button:hover {
    background-color: #151D51; }
  .circle-button__text {
    display: none;
    color: #151D51; }
    .circle-button__text:hover {
      color: #483FDD; }
  .circle-button--back::before, .circle-button--back::after {
    position: absolute;
    display: block;
    content: '';
    width: 9px;
    height: 2px;
    background-color: #fff; }
  .circle-button--back:before {
    top: 13px;
    left: 10px;
    transform: rotate(-45deg); }
  .circle-button--back:after {
    top: 18px;
    left: 10px;
    transform: rotate(45deg); }
  .circle-button--close::before, .circle-button--close::after {
    content: '';
    width: 2px;
    height: 16px;
    background-color: #fff;
    transform: rotate(45deg);
    position: absolute;
    top: calc(50% - 8px);
    left: 15px; }
  .circle-button--close::after {
    transform: rotate(-45deg); }

@media all and (max-width: 767px) and (orientation: portrait) {
  .circle-button {
    width: 0; }
    .circle-button__text {
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px; }
    .circle-button--close::before, .circle-button--close::after {
      background-color: #151D51; }
    .circle-button--close::before {
      top: 2px;
      left: -18px;
      height: 10px; }
    .circle-button--close::after {
      top: 9px;
      left: -18px;
      height: 10px; }
    .circle-button--close:hover::before, .circle-button--close:hover::after {
      background-color: #483FDD; } }
