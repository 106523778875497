.error-message {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #DF723B;
  letter-spacing: -0.24889px;
  margin: 0;
  padding-left: 32px; }
  .error-message__tooltip {
    display: none; }
  .error-message__wrapper {
    position: absolute;
    display: block;
    text-align: left;
    transform: translate(0, -29px); }
    .error-message__wrapper--checkbox {
      transform: translate(0, -3px); }

@media all and (max-width: 767px) {
  .error-message {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0;
    padding-right: 32px; }
    .error-message__wrapper {
      transform: translate(-8px, -45px); } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .error-message__wrapper {
    display: block;
    transform: translate(0, -44px); } }
