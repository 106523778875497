.device-item-popup {
  display: block;
  text-align: center;
  border: 1px solid #CACBCA;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 24px; }
  .device-item-popup__text-wrapper {
    padding-top: 8px; }

@media all and (max-width: 767px) {
  .device-item-popup {
    height: 276px;
    box-sizing: border-box;
    padding: 24px; }
    .device-item-popup__text-wrapper {
      padding-top: 11px; } }
