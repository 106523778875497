.subtitle {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.435556px;
  color: #151D51;
  width: 90%;
  margin: 0 auto;
  padding-top: 32px; }
  .subtitle--left {
    margin: 0; }
  .subtitle--bold {
    font-weight: 700; }
  .subtitle--form {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.41702px;
    width: 100%; }
  .subtitle--error {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.41702px;
    width: 100%;
    padding-top: 20px; }
  .subtitle--email {
    padding-top: 20px;
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.41702px;
    color: #151D51; }
  .subtitle--popup {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: left;
    letter-spacing: -0.41702px;
    color: #151D51;
    width: 100%;
    margin: 23px 0 0 0;
    padding: 0;
    max-width: 525px; }

@media all and (max-width: 767px) {
  .subtitle {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.24889px;
    font-weight: 400;
    padding-top: 10px;
    width: 100%; }
    .subtitle--error {
      display: none; }
    .subtitle--error-visible {
      display: block; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .subtitle {
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.41702px;
    font-weight: 400;
    width: 100%; }
    .subtitle--error {
      display: none; }
    .subtitle--error-visible {
      display: block; } }
