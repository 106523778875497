.icon__wrapper {
  content: '';
  position: absolute;
  right: 14px;
  display: block;
  box-sizing: border-box; }

.icon__background {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #483FDD; }

.icon__membership {
  margin-left: 2px; }

.icon--big {
  transform: scale(1.2); }

@media all and (max-width: 767px) {
  .icon {
    position: absolute;
    top: calc(50%);
    width: 24px;
    height: 24px;
    transform: translate(-50%, -50%); }
    .icon__wrapper {
      width: 48px;
      height: 48px;
      top: 0 !important;
      right: 0 !important; } }
