.gdpr-banner {
  position: fixed;
  z-index: 2147483647;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #6C1DA0;
  padding: 30px 40px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .gdpr-banner__content {
    max-width: 1360px;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center; }
  .gdpr-banner__text {
    color: #fff;
    font-size: 20px;
    line-height: 28px;
    width: calc(100% - 370px);
    float: left;
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-weight: 300; }
  .gdpr-banner__controls {
    float: right;
    margin-left: 75px;
    display: flex;
    width: 295px;
    justify-content: space-between;
    align-items: center; }
  .gdpr-banner__link {
    letter-spacing: inherit; }
  .gdpr-banner__button {
    border-radius: 30px;
    background: #5CD6C0;
    font-size: 18px;
    line-height: 20px;
    transition: all 0.3s linear;
    cursor: pointer;
    border: none;
    color: #151D51;
    padding: 17px 36px;
    width: auto;
    display: table;
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-weight: bold; }
    .gdpr-banner__button:hover, .gdpr-banner__button:focus {
      outline: none;
      background: #009796; }
    .gdpr-banner__button--transparent {
      box-shadow: 0 0 0 5px #5CD6C0 inset;
      background: none;
      color: #5CD6C0; }
      .gdpr-banner__button--transparent:hover, .gdpr-banner__button--transparent:focus {
        outline: none;
        box-shadow: 0 0 0 5px #009796 inset;
        color: #151D51;
        background: #009796; }
  .gdpr-banner__close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: -9px;
    left: -24px;
    cursor: pointer; }
    .gdpr-banner__close::before {
      content: '';
      width: 16px;
      height: 2px;
      background-color: #5CD6C0;
      transform: rotate(45deg);
      position: absolute;
      top: calc(50%);
      left: 4px; }
    .gdpr-banner__close::after {
      content: '';
      width: 16px;
      height: 2px;
      background-color: #5CD6C0;
      transform: rotate(-45deg);
      position: absolute;
      top: calc(50%);
      left: 4px; }

@media only screen and (max-width: 860px) {
  .gdpr-banner {
    padding: 48px 23px; }
    .gdpr-banner__content {
      flex-direction: column;
      align-items: flex-end; }
    .gdpr-banner__text {
      width: 100%; }
    .gdpr-banner__controls {
      margin: 0 auto;
      width: 275px; }
    .gdpr-banner__button {
      margin-top: 20px; }
    .gdpr-banner__close {
      width: 48px;
      height: 48px;
      top: -48px;
      left: -23px; }
      .gdpr-banner__close::before, .gdpr-banner__close::after {
        width: 18px;
        left: 12px; } }
