.device-download {
  display: block;
  background-color: #F5F5F5;
  border-radius: 20px;
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  color: #151D51;
  padding: 22px 0;
  width: 409px;
  min-height: 638px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box; }
  .device-download__block {
    position: relative;
    display: block; }
  .device-download__device-type {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px; }
  .device-download__image {
    width: 305px; }
  .device-download__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 32px;
    text-align: center; }
  .device-download__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
    max-width: 352px; }
  .device-download__item {
    display: inline-block;
    margin: 0 auto; }

@media all and (max-width: 767px) {
  .device-download__block {
    display: block; }
  .device-download__item {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    min-height: 525px; } }
