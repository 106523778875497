.popup {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  position: absolute;
  z-index: 2;
  display: block;
  color: #151D51;
  background-color: #fff;
  width: 864px;
  min-height: 450px;
  top: calc(96px + 72px);
  left: calc(50% - 432px);
  border-radius: 10px; }
  .popup__wrapper {
    padding: 96px 112px;
    display: block;
    text-align: left; }
  .popup__header {
    display: grid;
    grid-template-columns: 48px auto;
    grid-column-gap: 24px;
    align-items: center;
    height: 48px;
    margin-top: 6px; }
  .popup__icon {
    width: 48px;
    height: 48px;
    margin-right: 24px;
    align-self: flex-start; }
  .popup__close-button {
    top: 32px;
    left: 32px; }
  .popup__content {
    display: block;
    margin-top: 58px; }
  .popup__status {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.41702px;
    color: #009796;
    text-align: left;
    text-transform: capitalize; }
  .popup__title {
    font-weight: bold;
    font-size: 48px;
    line-height: 48px;
    color: #151D51; }
  .popup__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: left;
    letter-spacing: -0.41702px;
    color: #151D51;
    width: 100%;
    margin: 26px 0 0 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis; }
    .popup__subtitle--inactive {
      max-width: 500px; }
    .popup__subtitle--bold {
      font-weight: bold; }
  .popup__icon {
    width: 48px;
    height: 48px; }
    .popup__icon svg {
      transform: scale(1.3); }

@media all and (max-width: 767px) {
  .popup {
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    min-height: calc(100vh - 192px); }
    .popup__wrapper {
      padding: 140px 24px 0 24px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap; }
    .popup__content {
      margin: 0 0 72px 0;
      width: 100%; }
    .popup__header {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: 32px auto;
      height: 32px;
      margin-top: 3px; }
    .popup__icon {
      width: 32px;
      height: 32px; }
      .popup__icon svg {
        transform: scale(0.86); }
    .popup__subtitle {
      font-size: 18px;
      line-height: 22px;
      margin: 16px 0 0 0; }
      .popup__subtitle--short {
        max-width: 84%; }
      .popup__subtitle--bold {
        font-weight: 700; }
    .popup__title {
      font-size: 24px;
      line-height: 32px;
      transform: translateY(0); }
    .popup__close-button {
      top: 77px;
      left: 45px; } }

@media all and (max-width: 767px) and (orientation: landscape) {
  .popup {
    width: calc(100% - 64px);
    top: 104px;
    left: 32px;
    border-radius: 16px; }
    .popup__wrapper {
      padding: 86px 48px 48px; }
    .popup__content {
      margin: 0; }
    .popup__subtitle {
      overflow: hidden;
      text-overflow: ellipsis; }
    .popup__close-button {
      top: 24px;
      left: 24px; } }

@media all and (min-width: 320px) and (max-width: 350px) {
  .popup__header {
    height: 100%; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .popup {
    width: 704px;
    top: 168px;
    left: calc(50% - 352px);
    border-radius: 16px; }
    .popup__wrapper {
      padding: 96px 48px; }
    .popup__content {
      margin-top: 55px; }
    .popup__title {
      transform: translateY(2px); }
    .popup__subtitle {
      margin: 21px 0 0 0; }
    .popup__close-button {
      top: 32px;
      left: 32px; } }
