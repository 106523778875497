.page {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  display: block;
  position: relative;
  margin-bottom: -170px;
  background: rgba(202, 203, 202, 0.35); }
  .page--aside {
    background: linear-gradient(to left, #fff 50%, #5CD6C0 50%); }
  .page--white {
    background: #fff;
    overflow: hidden; }

.main {
  display: flex;
  min-height: calc(100vh - 192px);
  padding-top: 72px; }

.content {
  position: relative;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 50%; }
  .content-form__wrapper {
    display: block;
    width: 415px;
    margin: 0 auto;
    transition: height .25s ease; }
  .content__wrapper {
    max-width: 600px;
    margin: 0 auto; }
  .content--aside-right {
    display: block;
    padding: 24px 0; }
  .content--aside-full-height {
    padding: 24px 0; }
  .content--full-width {
    width: 100%;
    margin: 0 auto;
    flex-direction: column; }

.container {
  max-width: 1440px;
  margin: 0 auto; }

.text__link {
  cursor: pointer;
  color: #5CD6C0;
  text-decoration: none;
  letter-spacing: -0.24889px; }
  .text__link:hover {
    color: #7BDDCB; }
  .text__link:active, .text__link:focus {
    outline: none;
    color: #009796; }

@media only screen and (min-height: 825px) {
  .content--aside-right {
    display: flex;
    padding: 0;
    margin: 0 auto; } }

@media all and (max-width: 1023px) {
  .page {
    background: rgba(202, 203, 202, 0.35); }
    .page--aside {
      background: #fff; }
    .page--error {
      background: #fff; }
    .page--white {
      background: #fff; }
  .main {
    padding-top: 0;
    flex-wrap: wrap;
    flex-direction: row; }
    .main--mobile {
      min-height: 321px; }
  .container {
    max-width: 1023px;
    overflow: hidden; }
    .container--error-page {
      min-height: 300px; }
  .content {
    width: 100%;
    padding: 0 24px;
    display: block; }
    .content-form__wrapper {
      width: 100%; }
    .content__wrapper {
      padding: 41px 0 44px 0; }
    .content--aside-left {
      padding-top: 60px;
      background-color: #5CD6C0; }
    .content--aside-full-height, .content--aside-right {
      padding-top: 48px; }
  .text__link {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .main {
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 0;
    min-height: calc(100vh - 250px); }
  .content__wrapper {
    padding: 41px 0 48px 0; }
  .content--aside-right, .content--aside-left {
    padding: 72px 48px 0; }
  .content--aside-full-height {
    padding: 32px 48px 58px; } }
