.copyright {
  width: 100%;
  color: #fff;
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
  max-width: 180px;
  text-align: right;
  margin-left: 23px; }

@media all and (max-width: 767px) {
  .copyright {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
    padding-top: 39px;
    transform: translateX(2px); }
    .copyright__text {
      display: inline; } }

@media all and (min-width: 768px) and (max-width: 1370px) {
  .copyright {
    margin-left: 0;
    padding-left: 24px; } }
