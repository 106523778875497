.checkbox-group {
  display: block;
  margin-bottom: 25px;
  position: relative; }

.checkbox {
  position: absolute; }
  .checkbox__label-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent; }
    .checkbox__label-wrapper:hover .checkbox__label {
      border: 1px solid #5CD6C0; }
    .checkbox__label-wrapper:hover .checkbox__label__errors {
      border: 1px solid #DF723B; }
  .checkbox__label {
    position: absolute;
    width: 24px;
    height: 24px;
    border: 1px solid #CACBCA;
    border-radius: 8px;
    background: #fff;
    box-sizing: border-box; }
    .checkbox__label--errors {
      border: 1px solid #DF723B; }
  .checkbox__text {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    padding-left: 32px;
    cursor: pointer;
    letter-spacing: -0.24889px; }

.checkbox:focus + .checkbox__label {
  border: 1px solid #7BDDCB; }

.checkbox:checked + .checkbox__label {
  position: absolute;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 8px;
  background: #5CD6C0; }
  .checkbox:checked + .checkbox__label:hover, .checkbox:checked + .checkbox__label:focus {
    background: #7BDDCB; }

.checkbox:checked + .checkbox__label:before {
  content: '';
  position: absolute;
  top: calc(50% - 5px);
  left: 6px;
  height: 5px;
  width: 10px;
  border-left: 2px solid #151D51;
  border-bottom: 2px solid #151D51;
  transform: rotate(-45deg); }

@media all and (max-width: 767px) {
  .checkbox-group {
    padding-top: 10px;
    margin-bottom: 18px; }
  .checkbox__label-wrapper {
    align-items: baseline; }
  .checkbox__label {
    transform: translateY(-2px); }
  .checkbox__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .checkbox__label-wrapper {
    align-items: baseline; } }
