.rounded-area {
  display: inline-block;
  border: 1px solid #D9D6D3;
  box-sizing: border-box;
  border-radius: 2025px;
  padding: 4px 14px; }

@media all and (max-width: 767px) {
  .rounded-area {
    padding: 5px 16px; } }
