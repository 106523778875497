.thank-you-page {
  display: block;
  position: relative;
  top: calc(50% - 75px); }
  .thank-you-page__title {
    font-weight: bold;
    font-size: 60px;
    line-height: 64px;
    text-align: center;
    letter-spacing: -1.1px; }
  .thank-you-page__text {
    text-align: center;
    font-weight: 300;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.435556px;
    margin-top: 32px; }
  .thank-you-page__button-wrapper {
    display: inline-block;
    margin-top: 35px; }

@media all and (max-width: 767px) {
  .thank-you-page {
    top: calc(50% - 40px); }
    .thank-you-page__title {
      font-weight: bold;
      font-style: normal;
      font-size: 26px;
      line-height: 30px;
      letter-spacing: -0.44px; }
    .thank-you-page__text {
      text-align: center;
      margin-top: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.41702px; }
    .thank-you-page__button-wrapper {
      margin-top: 22px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .thank-you-page {
    position: relative;
    top: calc(50% - 75px);
    margin: 0 auto; } }

@media all and (max-width: 1023px) and (orientation: landscape) {
  .thank-you-page {
    margin: 124px auto 80px;
    top: 0; } }
