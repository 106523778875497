.apple-signin__logo {
  position: absolute;
  top: calc(50% - 14px);
  left: 28px;
  width: 22px; }

.apple-signin__button {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fff;
  color: #151D51;
  border: 1px solid #CACBCA;
  height: 64px;
  border-radius: 32px;
  box-sizing: border-box;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.42px;
  transition: border 0.33s ease-out;
  padding: 0 28px;
  margin: 0 auto 24px;
  max-width: 415px; }
  .apple-signin__button::-moz-placeholder {
    color: #CACBCA; }
  .apple-signin__button::placeholder {
    color: #CACBCA; }
  .apple-signin__button:hover, .apple-signin__button:focus {
    border: 1px solid #151D51; }
  .apple-signin__button:disabled {
    background: rgba(230, 226, 221, 0.3);
    border: 1px solid #CACBCA; }

@media all and (max-width: 767px) {
  .apple-signin__logo {
    position: absolute;
    top: 12px;
    left: 23px;
    width: 16px;
    height: auto; }
  .apple-signin__button {
    font-size: 16px;
    line-height: 22px;
    justify-content: center;
    letter-spacing: -0.24889px;
    height: 48px;
    padding: 0 23px;
    font-weight: 700;
    margin: 0 auto 24px;
    max-width: 415px; } }
