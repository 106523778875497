.form {
  display: block;
  width: 415px;
  margin: 0 auto;
  transition: height .25s ease; }
  .form__link-wrapper {
    display: block;
    max-width: 415px;
    margin: 24px auto 0; }
    .form__link-wrapper--login {
      text-align: left;
      margin-top: 32px; }
    .form__link-wrapper--resend {
      margin-top: 20px; }
    .form__link-wrapper--send-email-login {
      margin-top: 13px; }
  .form__link {
    cursor: pointer;
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: -0.41702px;
    color: #5CD6C0;
    text-decoration: none;
    display: inline-block; }
    .form__link--login {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px; }
    .form__link:hover {
      color: #7BDDCB; }
    .form__link:active, .form__link:focus {
      outline: none;
      color: #009796; }
  .form__link-description {
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.41702px;
    color: #151D51; }

.button-back {
  text-decoration: none;
  color: #5579f0;
  font-weight: 600; }

@media all and (max-width: 767px) {
  .form {
    width: 100%;
    margin-bottom: 60px;
    max-width: 415px; }
    .form.appear {
      max-height: 200px; }
    .form--change-password {
      margin-bottom: 0; }
    .form__link-wrapper {
      margin-top: 0;
      padding-top: 24px; }
      .form__link-wrapper--login {
        padding-top: 10px; }
      .form__link-wrapper--resend, .form__link-wrapper--send-email-login {
        padding-top: 21px; }
    .form__link {
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.24889px; }
    .form__link-description {
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.24889px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .form {
    width: 100%;
    max-width: 415px; }
    .form__link-wrapper--login {
      text-align: left;
      margin-top: 24px; }
    .form__link {
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.41702px; }
      .form__link--login {
        font-size: 16px;
        line-height: 24px; } }
