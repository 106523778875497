.form-signup--waoo .form__link-wrapper, .form-signup--stackCommerce .form__link-wrapper {
  display: none; }

.consent__desc {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.24889px;
  text-align: left;
  max-width: 98%; }

@media all and (max-width: 767px) {
  .consent__desc {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0;
    max-width: 90%; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .consent__desc {
    max-width: 100%; } }
