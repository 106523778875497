.sub-menu {
  position: relative;
  color: #151D51;
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  font-weight: 300; }
  .sub-menu:hover .header__menu-link, .sub-menu:focus .header__menu-link {
    color: #151D51; }
  .sub-menu__open .sub-menu__title {
    z-index: 3; }
  .sub-menu__link {
    text-decoration: none;
    font-weight: 200;
    color: #151D51;
    white-space: nowrap; }
  .sub-menu__list {
    display: block;
    opacity: 0;
    text-align: left;
    position: absolute;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid transparent;
    border-radius: 16px;
    padding: 40px 24px 22px 26px;
    top: -16px;
    left: -24px;
    transition: border 0.5s linear;
    margin-top: -1000px; }
    .sub-menu__list--open {
      opacity: 1;
      z-index: 2;
      border: 1px solid #CACBCA;
      margin-top: 0; }
      .sub-menu__list--open .sub-menu__item {
        opacity: 1; }
  .sub-menu__item {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.42px;
    color: #151D51;
    opacity: 0;
    padding-top: 14px;
    transition: opacity 0.5s linear; }
    .sub-menu__item:hover .sub-menu__link, .sub-menu__item:focus .sub-menu__link {
      color: #6C1DA0; }
  .sub-menu__title {
    display: flex;
    position: relative; }
  .sub-menu__arrow {
    display: block;
    position: relative;
    width: 20px;
    height: 24px; }
    .sub-menu__arrow::before, .sub-menu__arrow::after {
      position: absolute;
      display: block;
      content: '';
      width: 9px;
      height: 2px;
      background-color: #151D51;
      transition: transform .5s; }
    .sub-menu__arrow:before {
      top: 10px;
      right: 7px;
      transform: rotate(45deg); }
    .sub-menu__arrow:after {
      top: 10px;
      right: 1px;
      transform: rotate(-45deg); }
    .sub-menu__arrow--up {
      z-index: 3; }
      .sub-menu__arrow--up:before {
        top: 10px;
        right: 7px;
        transform: rotate(-45deg); }
      .sub-menu__arrow--up:after {
        top: 10px;
        right: 1px;
        transform: rotate(45deg); }

@media all and (max-width: 1023px) {
  .sub-menu__open {
    margin-bottom: 0; }
  .sub-menu__list {
    padding: 0;
    position: relative;
    margin-top: 0;
    border: none;
    overflow: hidden;
    background-color: transparent;
    transition: height 0.5s linear, opacity 0.5s linear;
    height: 0;
    left: 0;
    top: 0; }
    .sub-menu__list--open {
      display: block;
      position: relative;
      border: none;
      text-align: left;
      left: 0;
      padding: 0; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .sub-menu__item {
    font-size: 24px;
    line-height: 54px;
    letter-spacing: -0.44px;
    color: #151D51;
    padding-top: 18px; }
    .sub-menu__item:first-child {
      padding-top: 20px; }
  .sub-menu__arrow {
    margin-left: auto; }
    .sub-menu__arrow::before, .sub-menu__arrow::after {
      position: absolute;
      display: block;
      content: '';
      width: 14px;
      height: 2px;
      top: 26px;
      background-color: #151D51;
      transition: transform .3s; }
    .sub-menu__arrow:before {
      right: 16px; }
    .sub-menu__arrow:after {
      right: 7px; } }

@media all and (max-width: 767px) {
  .sub-menu__item {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.44px;
    color: #151D51;
    padding-top: 30px; }
    .sub-menu__item:first-child {
      padding-top: 32px; }
    .sub-menu__item:last-child {
      padding-bottom: 32px; }
  .sub-menu__title {
    display: flex;
    justify-content: space-between;
    width: 100%; }
  .sub-menu__arrow::before, .sub-menu__arrow::after {
    position: absolute;
    display: block;
    content: '';
    width: 9px;
    height: 2px;
    top: 13px;
    background-color: #151D51;
    transition: transform .3s; }
  .sub-menu__arrow:before {
    right: 7px; }
  .sub-menu__arrow:after {
    right: 1px; } }
