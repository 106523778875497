.footer {
  background: #151D51; }
  .footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 64px;
    height: 120px;
    box-sizing: border-box; }
  .footer__logo-block {
    padding-right: 23px; }
  .footer__logo {
    outline: none; }
  .footer__menu {
    display: grid;
    flex-wrap: wrap;
    -moz-column-gap: 32px;
         column-gap: 32px;
    grid-template-columns: 102px 100px 40px 106px 160px 100px; }
  .footer__menu-link {
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-size: 16px;
    letter-spacing: -0.24889px;
    font-weight: bold;
    line-height: 24px;
    text-decoration: none;
    color: #fff; }
    .footer__menu-link:hover, .footer__menu-link:focus {
      outline: none;
      color: #CACBCA; }

@media all and (max-width: 767px) {
  .footer__container {
    justify-content: center;
    padding: 48px 0;
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap; }
  .footer__menu {
    display: flex;
    flex-wrap: wrap;
    padding-top: 61px;
    flex-direction: column;
    text-align: center; }
  .footer__menu-item {
    padding-bottom: 21px;
    margin-right: 0; }
  .footer__menu-link {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.24889px; } }

@media all and (min-width: 768px) and (max-width: 1370px) {
  .footer__container {
    padding: 40px 48px;
    height: 250px;
    align-items: flex-start; }
  .footer__menu {
    display: grid;
    margin: 0 auto;
    -moz-column-gap: 36px;
         column-gap: 36px;
    grid-template-columns: 160px 105px; }
  .footer__menu-item {
    height: 48px;
    margin-right: 0; }
  .footer__menu-link {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24889px; }
  .footer__logo {
    display: block;
    align-self: flex-start;
    padding-right: 24px; } }
