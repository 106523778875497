.title {
  font-size: 62px;
  line-height: 69px; }
  .title--form {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    letter-spacing: -0.435556px; }
  .title--bold {
    font-size: 60px;
    line-height: 64px;
    font-weight: 700;
    letter-spacing: -1.1px; }
  .title--aside {
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 64px;
    text-align: center;
    letter-spacing: -1.1px;
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    color: #151D51;
    max-width: 600px; }

@media all and (max-width: 1023px) {
  .title {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: -0.44px; }
    .title--break-words {
      max-width: 200px;
      margin: 0 auto; }
      .title--break-words-forgot {
        max-width: 240px;
        margin: 0 auto; } }

@media all and (max-width: 767px) and (orientation: landscape) {
  .title {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: -0.44px; }
    .title--popup {
      font-size: 48px;
      line-height: 54px;
      letter-spacing: -0.65px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1.1px; } }
