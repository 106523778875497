.sso button:only-child {
  margin-bottom: 24px; }

@media all and (max-width: 1023px) {
  .sso button:only-child {
    margin-bottom: 24px; } }

@media all and (max-width: 767px) {
  .sso button:only-child {
    margin-bottom: 6px; } }
