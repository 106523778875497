.loader {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  text-align: center;
  opacity: 1; }

@media all and (max-width: 767px) {
  .loader {
    position: relative; }
    .loader--popup {
      position: relative; }
    .loader--page {
      position: relative;
      padding-top: 56px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .loader--page {
    min-height: calc(100vh - 200px);
    position: fixed; }
  .loader--main {
    position: fixed; } }
