.devices {
  display: block;
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif; }
  .devices__header {
    display: block; }
  .devices__status {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -0.41702px;
    color: #009796;
    margin-right: 44px; }
    .devices__status::first-letter {
      text-transform: uppercase; }
    .devices__status--inactive {
      color: #6C1DA0; }
  .devices__quantity {
    display: inline-block;
    text-align: center;
    box-sizing: border-box;
    height: 32px;
    line-height: 32px;
    background-color: #009796;
    border-radius: 70px;
    padding: 0 16px;
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
    min-width: 125px;
    transform: translateY(-3px); }
    .devices__quantity--inactive {
      background-color: #6C1DA0; }
  .devices__title {
    display: inline-block;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -0.435556px;
    margin-top: 13px; }
  .devices__description {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24889px;
    margin-top: 14px;
    max-width: 85%; }
  .devices__subtitle {
    margin-top: 32px; }
  .devices__connect-more {
    margin-top: 58px;
    margin-bottom: 26px; }
  .devices__block-title {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.435556px; }
  .devices__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24889px; }
  .devices__subtext {
    display: block; }
  .devices__connect-more-text {
    margin-top: 8px; }

@media all and (max-width: 767px) {
  .devices__header {
    margin-top: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .devices__status {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.24889px;
    margin-right: 0; }
  .devices__quantity {
    transform: translateY(0); }
  .devices__title {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.41702px;
    margin-top: 21px;
    max-width: 70%; }
    .devices__title--inactive {
      max-width: 100%; }
  .devices__subtitle {
    margin-top: 22px; }
  .devices__description {
    margin-top: 18px;
    max-width: 95%; }
  .devices__connect-more-title {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.41702px;
    max-width: 70%; }
  .devices__connect-more {
    display: none;
    margin-top: 37px; }
  .devices__connect-more-text {
    margin-top: 18px; }
  .devices__block-title {
    padding-top: 12px;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.41702px; }
  .devices__text {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.24889px; }
  .devices__subtext {
    display: inline-block; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .devices__subtitle {
    margin-top: 27px; }
  .devices__status {
    margin-right: 38px; }
  .devices__quantity {
    transform: translateY(0); }
  .devices_description {
    max-width: 95%; }
  .devices__connect-more-text {
    max-width: 80%; }
  .devices__connect-more {
    display: none; } }
