.error-page {
  display: block;
  max-width: 666px;
  margin: 142px auto; }
  .error-page__back {
    display: none !important; }

@media all and (max-width: 767px) {
  .error-page {
    margin-top: 102px;
    margin-bottom: 0; }
    .error-page__back {
      display: block !important; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .error-page {
    position: relative;
    top: calc(50% - 75px);
    margin: 0 auto; }
    .error-page__back {
      display: block !important; } }

@media all and (max-width: 1023px) and (orientation: landscape) {
  .error-page {
    margin: 136px auto 20px;
    top: 0; } }
