.disconnect-confirm__button {
  min-width: 94px; }
  .disconnect-confirm__button--reject {
    margin-right: 32px; }

@media all and (max-width: 767px) {
  .disconnect-confirm__button-wrapper {
    margin-top: 49px; }
  .disconnect-confirm__button--reject {
    margin-right: 24px; } }
