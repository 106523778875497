.separator {
  display: block;
  position: relative;
  margin-bottom: 24px; }
  .separator__text {
    line-height: 24px; }
  .separator::before, .separator::after {
    position: absolute;
    display: block;
    content: '';
    top: 57%;
    height: 1px;
    width: 44%;
    background-color: #CACBCA;
    transition: transform .3s; }
  .separator::before {
    left: 8px; }
  .separator::after {
    right: 8px; }

@media all and (max-width: 767px) {
  .separator {
    max-width: 415px;
    margin: 0 auto 6px; }
    .separator::before, .separator::after {
      top: 57%; }
    .separator::before {
      left: 0; }
    .separator::after {
      right: 0; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .separator {
    max-width: 415px;
    margin: 0 auto 24px; } }
