html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: "Moderat";
  src: url("/fonts/Moderat-Light.otf");
  font-weight: 300; }

@font-face {
  font-family: "Moderat";
  src: url("/fonts/Moderat-Regular.otf");
  font-weight: 400; }

@font-face {
  font-family: "Moderat";
  src: url("/fonts/Moderat-Medium.otf");
  font-weight: 500; }

@font-face {
  font-family: "Moderat";
  src: url("/fonts/Moderat-Bold.otf");
  font-weight: 700; }

.fade-enter {
  opacity: 0; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in; }

.fade-leave {
  opacity: 1; }

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 300ms ease-in; }

.word {
  display: inline-block;
  transition: opacity 2000ms cubic-bezier(0.24, 0.25, 0, 0.93), transform 1500ms cubic-bezier(0.24, 0.25, 0, 0.93);
  transform: translateY(30px);
  opacity: 0.01; }

.word.show {
  transform: translateY(0);
  opacity: 1; }

.popup-transition-appear {
  opacity: 0.01;
  transform: scale(0.9); }

.popup-transition-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms; }

.popup-transition-appear-done {
  opacity: 1; }

.popup-transition-exit {
  opacity: 1;
  transform: scale(1); }

.popup-transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms; }

@keyframes textItemAppear {
  0% {
    transform: translateY(5px);
    opacity: 0.01; }
  75% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes pageItemAppear {
  0% {
    transform: translateY(30px);
    opacity: 0.01; }
  75% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes buttonItemAppear {
  0% {
    opacity: 0.01; }
  75% {
    transform: translateY(0);
    opacity: 1; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

.slide__button {
  animation-name: buttonItemAppear;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.24, 0.25, 0, 0.93);
  will-change: opacity, transform;
  opacity: 0; }

.slide {
  animation-name: pageItemAppear;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.24, 0.25, 0, 0.93);
  will-change: opacity, transform;
  opacity: 0.01; }

.slide:nth-child(0n) {
  animation-delay: 0s; }

.slide:nth-child(1n) {
  animation-delay: 0.3s; }

.slide:nth-child(2n) {
  animation-delay: 0.6s; }

.slide:nth-child(3n) {
  animation-delay: 0.9s; }

.slide:nth-child(4n) {
  animation-delay: 1.2s; }

.slide:nth-child(5n) {
  animation-delay: 1.5s; }

.slide:nth-child(6n) {
  animation-delay: 1.8s; }

.slide:nth-child(7n) {
  animation-delay: 2.1s; }

.slide:nth-child(8n) {
  animation-delay: 2.4s; }

.slide:nth-child(9n) {
  animation-delay: 2.7s; }

.slide:nth-child(10n) {
  animation-delay: 3s; }

.slide__section {
  animation-name: pageItemAppear;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.24, 0.25, 0, 0.93);
  will-change: opacity, transform;
  opacity: 0.01; }

.slide__section:nth-child(0n) {
  animation-delay: 0s; }

.slide__section:nth-child(1n) {
  animation-delay: 0.2s; }

.slide__section:nth-child(2n) {
  animation-delay: 0.4s; }

.slide__section:nth-child(3n) {
  animation-delay: 0.6s; }

.slide__section:nth-child(4n) {
  animation-delay: 0.8s; }

.slide__section:nth-child(5n) {
  animation-delay: 1s; }

.slide__section:nth-child(6n) {
  animation-delay: 1.2s; }

.slide__section:nth-child(7n) {
  animation-delay: 1.4s; }

.slide__section:nth-child(8n) {
  animation-delay: 1.6s; }

.slide__section:nth-child(9n) {
  animation-delay: 1.8s; }

.slide__section:nth-child(10n) {
  animation-delay: 2s; }

.animate-in {
  animation: pageAppear;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.24, 0.25, 0, 0.93);
  will-change: opacity;
  opacity: 0; }

@keyframes pageAppear {
  0% {
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    opacity: 1; } }

#root {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  color: #151D51; }
