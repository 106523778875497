.verify-email {
  display: block;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 1;
  opacity: 0.01;
  transition: opacity .3s linear;
  width: 0;
  height: 0;
  overflow: hidden; }
  .verify-email.appear {
    width: 100%;
    height: 100%;
    opacity: 1; }
    .verify-email.appear .verify-email__message {
      opacity: 1;
      transform: translateY(0); }
  .verify-email__message {
    max-width: 450px;
    left: calc(50% - 189px);
    top: 226px;
    opacity: 0.1;
    position: absolute;
    transform: translateY(70px);
    transition: transform 1s cubic-bezier(0.24, 0.25, 0, 0.99), opacity 0.5s linear; }
  .verify-email__text {
    max-width: 370px;
    font-weight: normal;
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.41702px;
    margin: 13px auto 0; }
  .verify-email__title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.435556px;
    margin-top: 25px; }
  .verify-email__icon-wrapper {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #CACBCA;
    display: block;
    position: relative;
    margin: 0 auto; }
  .verify-email__icon {
    position: absolute;
    top: calc(50% - 12px);
    left: calc(50% - 12px); }
    .verify-email__icon path {
      stroke: #fff; }

@media all and (max-width: 767px) {
  .verify-email__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.41702px;
    max-width: 60%;
    margin: 15px auto 0; }
  .verify-email__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.24889px;
    max-width: 80%; }
  .verify-email__message {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    top: 48px;
    left: 0; }
  .verify-email__icon-wrapper {
    width: 48px;
    height: 48px; } }
