.device-download__image--mobile {
  width: 280px;
  margin-top: 11px;
  margin-left: 24px; }

.device-download__qr {
  margin: 40px 0 42px; }

.device-download__source-button {
  cursor: pointer; }
  .device-download__source-button:first-child {
    margin-right: 8px; }

@media all and (max-width: 767px) {
  .device-download__image--mobile {
    margin-top: 0;
    margin-left: 0;
    max-height: 400px; }
  .device-download__title {
    display: none; }
  .device-download__qr {
    display: none; }
  .device-download__source-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px; }
  .device-download__source-button {
    height: 48px;
    display: block;
    transform: scale(1.5); }
    .device-download__source-button:first-child {
      margin-right: 0; } }
