.form__group {
  position: relative;
  margin: 0 auto;
  max-width: 415px; }

.form__input {
  font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  color: #151D51;
  border: 1px solid #CACBCA;
  border-radius: 32px;
  padding: 18px 46px 18px 32px;
  box-sizing: border-box;
  outline: none;
  font-size: 18px;
  margin-bottom: 24px;
  letter-spacing: -0.42px;
  line-height: 26px;
  transition: border 0.33s ease-out; }
  .form__input::-moz-placeholder {
    color: #CACBCA; }
  .form__input::placeholder {
    color: #CACBCA; }
  .form__input:hover, .form__input:focus {
    border: 1px solid #151D51; }
  .form__input:disabled {
    background: rgba(230, 226, 221, 0.3);
    border: 1px solid #CACBCA; }
  .form__input--error {
    padding: 18px 50px 18px 32px;
    border: 1px solid #DF723B; }
    .form__input--error:hover, .form__input--error:focus {
      border: 1px solid #DF723B; }
  .form__input--succeeded {
    border: 1px solid #5CD6C0; }
    .form__input--succeeded:hover, .form__input--succeeded:focus {
      border: 1px solid #5CD6C0; }
  .form__input--increased-circle {
    font-size: 36px;
    max-height: 64px; }

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0; }

@media all and (max-width: 767px) {
  .form__input {
    border-radius: 24px;
    padding: 11px 37px 12px 23px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24889px;
    margin-bottom: 24px;
    box-sizing: border-box;
    height: 48px; }
    .form__input--error {
      margin-bottom: 46px; }
    .form__input--increased-circle {
      font-size: 16px;
      max-height: 48px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .form__input--error {
    margin-bottom: 46px; }
  .form__input--increased-circle {
    font-size: 16px; } }
