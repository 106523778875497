.circle {
  display: block;
  background-color: #009796;
  border-radius: 50%;
  width: 208px;
  height: 208px; }
  .circle--inactive {
    background-color: #7C42D5; }
  .circle__wrapper {
    display: flex;
    justify-content: center; }
  .circle__text {
    text-align: center;
    padding-top: 50px;
    overflow: hidden;
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    overflow: hidden;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    max-width: 168px;
    margin: 0 auto; }

@media all and (max-width: 767px) {
  .circle__text {
    padding-top: 50px; } }
