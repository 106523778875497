.download {
  display: block;
  background: #F5F6F5;
  border-radius: 10px;
  padding: 24px;
  margin-top: 24px; }
  .download__block {
    box-sizing: border-box;
    min-height: 190px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 10px;
    padding: 24px;
    margin-bottom: 24px; }
    .download__block:last-child {
      margin-bottom: 0; }
  .download__aside--source {
    display: block;
    width: 180px;
    text-align: center;
    align-self: flex-end; }
  .download__aside--source-desktop:hover .download__source-link {
    outline: none;
    color: #6C1DA0; }
  .download__header {
    display: flex;
    align-items: center;
    height: 32px;
    justify-content: flex-start; }
  .download__icon--download {
    display: block;
    margin: 0 auto;
    padding-bottom: 14px; }
  .download__icon--header {
    width: 32px;
    height: 32px;
    margin-right: 18px; }
    .download__icon--header svg {
      transform: scale(0.86); }
  .download__icon--download-desktop {
    cursor: pointer; }
  .download__source {
    display: none; }
  .download__title {
    display: inline-block;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.41702px; }
  .download__instructions {
    display: block;
    margin-top: 14px; }
  .download__instruction-item {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 6px;
    counter-reset: item; }
    .download__instruction-item:last-child {
      margin-bottom: 0; }
  .download__instruction-item-count {
    padding-right: 4px; }
  .download__source-link {
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.54px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #151D51;
    cursor: pointer; }
  .download__source-text {
    white-space: nowrap;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px; }

@media all and (max-width: 767px) {
  .download {
    margin-top: 42px;
    padding: 8px;
    display: block; }
    .download__block {
      display: grid;
      padding: 16px 0;
      justify-content: center;
      margin-bottom: 8px;
      height: 268px; }
      .download__block--desktop {
        display: none; }
    .download__header {
      display: block;
      height: auto;
      text-align: center;
      margin-top: -8px; }
    .download__aside {
      display: block;
      width: 100%;
      text-align: center; }
      .download__aside--source {
        display: none; }
    .download__instructions {
      display: none; }
    .download__icon--download {
      display: none; }
    .download__icon--header {
      margin: 6px auto; }
    .download__title {
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.41702px; }
    .download__text {
      display: block;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      max-width: 65%;
      margin: 0 auto; }
    .download__source {
      display: block;
      margin-top: 12px;
      margin-bottom: 30px; }
    .download__source-link {
      display: block;
      margin-top: 36px;
      letter-spacing: -0.54px; }
    .download__source-text {
      margin-top: 18px; }
    .download__source-buttons {
      display: block;
      margin-top: 14px; }
    .download__source-button:first-child {
      margin-right: 9px; } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .download {
    display: block;
    margin-top: 42px; }
    .download__header {
      margin-top: 2px; }
    .download__block--desktop {
      display: none; } }
