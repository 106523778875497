.device-item {
  display: block;
  background-color: #F5F6F5;
  border-radius: 10px;
  min-height: 180px;
  padding: 24px;
  box-sizing: border-box;
  margin-bottom: 24px; }
  .device-item__loader {
    width: 100%;
    height: 204px; }
  .device-item__aside {
    display: inline-block;
    width: 51%; }
    .device-item__aside--right {
      width: 49%;
      transform: translateY(-13px); }
  .device-item__header {
    display: flex;
    align-items: center; }
  .device-item__device-info-block {
    margin-left: 71px;
    margin-top: 13px; }
  .device-item__device-info {
    display: flex; }
  .device-item__device-info-item {
    display: inline-block;
    margin-bottom: 10px; }
    .device-item__device-info-item:last-child {
      margin-bottom: 0; }
  .device-item__device-info-name {
    width: 92px;
    text-align: left;
    font-size: 14px;
    line-height: 21px; }
  .device-item__device-info-value {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    width: calc(100% - 92px);
    overflow: hidden; }
  .device-item__type {
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.41702px;
    margin-left: 22px;
    text-transform: capitalize; }
  .device-item__info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px; }
  .device-item__number {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24889px; }
  .device-item__status {
    margin-left: 14px;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #009796; }

@media all and (max-width: 767px) {
  .device-item {
    box-sizing: border-box;
    padding: 16px;
    position: relative;
    height: 276px;
    margin-bottom: 16px; }
    .device-item__info {
      position: absolute;
      top: 35px; }
    .device-item__status {
      margin-left: 52px; }
    .device-item__device-info-block {
      position: absolute; }
    .device-item__aside {
      display: block;
      width: 100%; }
    .device-item__header {
      height: 32px;
      margin-left: -8px; }
    .device-item__icon {
      width: 32px;
      height: 32px; }
      .device-item__icon svg {
        transform: scale(0.86); }
    .device-item__type {
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: -0.41702px;
      margin-left: 15px; }
    .device-item__device-info-block {
      margin-left: 0;
      margin-top: 0;
      top: 104px;
      position: absolute; }
    .device-item__device-info-name {
      width: 111px; }
    .device-item__device-info-item {
      margin-bottom: 9px; }
    .device-item__button-wrapper {
      position: absolute;
      margin-top: 0;
      top: 161px;
      left: calc(50% - 75px); } }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .device-item {
    height: 180px;
    padding: 24px 24px 48px 24px; }
    .device-item__aside {
      transform: translateY(-19px); }
      .device-item__aside--right {
        box-sizing: border-box;
        padding-right: 40px;
        transform: translateY(-33px); }
    .device-item__info {
      justify-content: flex-end; }
    .device-item__button-wrapper {
      justify-content: flex-end;
      margin-top: 26px; } }
