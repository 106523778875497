.section {
  opacity: 0.01;
  transform: translateY(50px);
  background-color: #fff;
  width: 272px;
  display: inline-block;
  margin-top: 66px;
  margin-bottom: 144px;
  border-radius: 10px;
  box-sizing: border-box;
  text-align: left;
  padding-bottom: 15px;
  margin-left: 48px;
  cursor: pointer; }
  .section:first-child {
    margin-left: 0; }
  .section--disabled {
    pointer-events: none; }
  .section--active .section__icon {
    background-color: #009796; }
  .section--active .section__status {
    color: #009796; }
  .section--inactive .section__icon {
    background-color: #6C1DA0; }
  .section--inactive .section__status {
    color: #6C1DA0; }
  .section__icon {
    width: 32px;
    height: 32px;
    margin-right: 16px; }
    .section__icon svg {
      transform: scale(0.86); }
  .section__circle-wrapper {
    height: 150px;
    overflow: hidden;
    margin-top: 44px; }
    .section__circle-wrapper .circle__text {
      padding-top: 64px; }
  .section__title {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    flex-direction: column;
    width: calc(100% - 48px);
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 20px;
    line-height: 28px;
    color: #151D51;
    letter-spacing: 0; }
  .section__header {
    display: flex; }
  .section__status {
    font-family: "Moderat", "Helvetica Neue", "PingFang SC", Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.24889px;
    color: #009796;
    padding: 0;
    text-align: left;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; }
    .section__status::first-letter {
      text-transform: uppercase; }
  .section__description {
    margin-top: 8px;
    font-size: 16px;
    line-height: 24px;
    color: #151D51;
    min-height: 48px; }
  .section__text--bold {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #fff; }
    .section__text--bold:first-letter {
      text-transform: capitalize; }
  .section__text--lowercase {
    text-transform: lowercase; }
    .section__text--lowercase:first-letter {
      text-transform: lowercase; }
  .section__button-wrapper {
    margin-top: 16px; }
  .section__button {
    min-width: 190px;
    padding: 0 20px;
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis; }
  .section__content {
    background-color: #F3F5F6;
    border-radius: 10px 10px 0 0;
    padding: 16px 16px 0; }

@media all and (min-width: 768px) and (max-width: 1023px) {
  .section {
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0; }
    .section__title {
      font-size: 20px;
      line-height: 24px; } }

@media all and (max-width: 767px) {
  .section {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 24px; }
    .section__icon {
      width: 32px;
      height: 32px; }
      .section__icon svg {
        transform: scale(0.86); }
    .section:last-child {
      margin-bottom: 0; }
    .section__description {
      line-height: 24px;
      margin-top: 8px; }
    .section__circle-wrapper {
      height: 150px; }
    .section__button-wrapper {
      padding: 0 16px; } }
