.notification {
  display: block;
  background-color: #5CD6C0;
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%; }
  .notification__content {
    display: block;
    padding: 10px 48px;
    text-align: center;
    background-color: #5CD6C0;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.43556px;
    color: #151D51; }

@media all and (min-width: 320px) and (max-width: 767px) {
  .notification__content {
    padding: 10px 24px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.248889px; } }
